import {
    useEffect, useState, useContext
} from 'react';
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/views-styles/main-styles';
import { Div, Img } from '../../hybrid/wrappers';
import { Top } from './newComponents/Top/Top';
import arrow from '../../public/src/img/main/arrow-top.svg';
import { useRouter } from 'next/router';
import UserContext from '../../common/utils/getContext';
import { Trust } from './newComponents/Trust/Trust';
import { Production } from './newComponents/Production/Production';
import { Partners } from './newComponents/Partners/Partners';
import { Community } from './newComponents/Community/Community';
import { Advantages } from './newComponents/Advantages/Advantages';
import { Blogers } from './newComponents/Blogers/Blogers';
import { President } from './newComponents/President/President';
import { Products } from './newComponents/Products/Products';
import { Questions } from './newComponents/Questions/Questions';
import { Services } from './newComponents/Services/Services';
import { Advice } from './newComponents/Advice/Advice';

const MainView = ({ styles }) => {
    const [scrollY, setScrollY] = useState(0);
    const [buttonOpen, setButtonOpen] = useState(false)

    useEffect(() => {
        document.querySelector('html').style.overflowX = 'hidden';
    }, []);

    const onToTheTopClick = () => {
        const targetElement = document.getElementById('main-page-header');

        targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    useEffect(() => {
        const container = document.querySelector('body');
        let prevScrollY = 0;

        const handleScroll = (e) => {
            const currentScrollY = e.target.scrollTop;
            setScrollY(currentScrollY);

            if (currentScrollY < prevScrollY) {
                setButtonOpen(true)
            } else {
                setButtonOpen(false)
            }

            prevScrollY = currentScrollY;
        };

        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <Div className={'main-page'}>
            <Top />
            <President />
            <Trust mainStyles={styles} />
            <Products />
            <Production />
            {/*<Advice />*/}
            <Services />
            <Community />
            <Partners />
            <Advantages mainStyles={styles} />
            <Blogers />
            <Questions />
            { <div onClick={onToTheTopClick} className={scrollY > 200 && buttonOpen ? 'arrowTopLink' : 'arrowTopLinkHide'}>
                <Img src={arrow.src} width={15} height={18} loading='lazy' />
            </div> }


        </Div>
    );
};

export default Styles(MainView, getStyles);