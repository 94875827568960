import React, {
    useContext, useState, useEffect
} from 'react';
import {
    Div, BlankLink, svgSprite, Img, P, A
} from '../../../hybrid/wrappers';
import UserContext from '../../../common/utils/getContext';
import { footerInfo } from '../../store/components/initData';
import Styles from '../../../common/decorators/Styles';
import getStyles from '../../../project/styles/layouts-styles/single-layout-styles';
import FooterMenuMain from '../../../widgets/menu/single/FooterMenuMain';
import T from '../../../common/components/T';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
const project = require('../../../scripts/configs/project');
import visamaster from '../../../public/src/img/footer/visamaster.png';
// import { useRouter } from 'next/router';
import { navigate } from '../../../common/utils/router';

const SingleFooter = ({ styles }) => {
    const { lang, time } = useContext(UserContext);
    const [mobile, setMobile] = useState(false);
    // const router = useRouter()
    const widthDimensions = useWindowDimensions();
    useEffect(() => {
        widthDimensions < 768 ? setMobile(true) : setMobile(false);
    }, [widthDimensions]);
    const currentYear = time().year();

    // const currentUrl = router.pathname


    // const onToTheTopClick = () => {
    //     const targetElement = document.getElementById('main-page-header');
    //     if (currentUrl === '/' || currentUrl === '/[lang]'){
    //         targetElement.scrollIntoView({
    //             behavior: 'smooth',
    //             block: 'start'
    //         });
    //     } else {
    //         navigate('/', '/', lang)
    //     }
    // }

    return (
        <Div styles={styles.footerWrap}>
            <Div styles={{ ...styles.blockXl }}>
                <Div styles={{ ...styles.footerInfoMainWrap }}>
                    <Div styles={{ ...styles.footerInfoMainLeft }}>
                        <Div
                            styles={{ ...styles.mainMenuLogoMainPageFooter, cursor: 'pointer' }}
                            data-uitest='77_uitest'
                        >
                            <a href={`/${lang}`} onClick={(e) => {
                                e.preventDefault()
                                navigate('/', '/', lang)
                            }}>
                                {svgSprite('main-logo-svg', {
                                    width: '118',
                                    height: '44',
                                    ...styles.mainMenuLogoImg
                                })}
                            </a>
                        </Div>
                        {!mobile ?
                            <Div styles={styles.footerInfoContactMain}>
                                <Div styles={styles.footerAddress}>
                                    {svgSprite('location_1', { width: '24px', height: '24px' })}
                                    <P styles={styles.footerAddressText}>{footerInfo?.address}</P>
                                </Div>
                                <Div styles={styles.footerContactTitle}>
                                    <T textName='socialFooterTelTech' defaultStr='Техническая поддержка' page='all' />
                                </Div>
                                <Div styles={styles.footerPhoneWrap}>
                                    <BlankLink
                                        icon={svgSprite('whatsapp_1', { width: '24px', height: '24px' })}
                                        styles={{ ...styles.footerInfoPhoneMain }}
                                        title={footerInfo?.whatsapp_tech?.number}
                                        href={footerInfo?.whatsapp_tech?.link}
                                        effects={{ hover: styles.footerInfoHover }}
                                    />
                                </Div>
                                <Div styles={styles.footerContactTitle}>
                                    <T textName='socialFooterTelCons' defaultStr='Консультация по продуктам' page='all' />
                                </Div>
                                <Div styles={styles.footerPhoneWrap}>
                                    <BlankLink
                                        icon={svgSprite('phone_1', { width: '24px', height: '24px' })}
                                        styles={{ ...styles.footerInfoPhoneMain }}
                                        title={footerInfo?.phone?.title}
                                        href={`tel:${footerInfo?.phone?.number}`}
                                        effects={{ hover: styles.footerInfoHover }}
                                    />
                                </Div>
                                <Div styles={styles.footerPhoneWrap}>
                                    <BlankLink
                                        icon={svgSprite('whatsapp_1', { width: '24px', height: '24px' })}
                                        styles={{ ...styles.footerInfoPhoneMain }}
                                        title={footerInfo?.whatsapp_cons?.number}
                                        href={footerInfo?.whatsapp_cons?.link}
                                        effects={{ hover: styles.footerInfoHover }}
                                    />
                                </Div>
                            </Div>
                            : null}
                    </Div>
                    <Div styles={styles.footerInfoMainCenter}>
                        <FooterMenuMain mobile={mobile} styles={styles} />
                    </Div>
                    {!mobile ?
                        <>
                            <Div styles={styles.singleFooterSocialWrapperMain}>
                                <Div styles={styles.footerSocialTitleMain}>
                                    <T textName='socialFooterTittle' defaultStr='Наши социальные сети' page='all' />
                                </Div>
                                <Div styles={styles.footerSocialMain}>
                                    {footerInfo.social.map((elem, idx) =>
                                        <>
                                            <Div key={`social${idx}`} styles={styles.footerSocialIconMainItem}>
                                                <Div
                                                    key={`social${idx}`}
                                                    styles={styles.footerSocialIconMain}
                                                    data-uitest='70_uitest'
                                                    onClick={() => {
                                                        elem.link ? window.open(elem.link, '_blank') : false;
                                                    }}
                                                    effects={{ hover: { ...styles.footerSocialIconMainHover } }}
                                                >
                                                    {svgSprite(elem.icon, {
                                                        style: {
                                                            width: elem.width,
                                                            height: elem.height,
                                                            fill: 'none'
                                                        }
                                                    })}
                                                </Div>
                                            </Div>
                                        </>)}
                                    <Div key={`social${5}`} styles={styles.footerSocialIconMainItem}>
                                        <Div
                                            key={`social${5}`}
                                            styles={styles.footerSocialIconMain}
                                            onClick={() => {
                                                true ? window.open('https://t.me/alhadayainfo_bot', '_blank') : false;
                                            }}
                                            effects={{ hover: { ...styles.footerSocialIconMainHover } }}
                                        >
                                            {svgSprite('telegram-alt', {
                                                style: {
                                                    width: '17px',
                                                    height: '17px',
                                                    margin: '2px 2px 0px 0px',
                                                    fill: '#BCA57F'
                                                }
                                            })}
                                        </Div>
                                    </Div>
                                </Div>
                                <Div styles={styles.footerSocialMainContact}>
                                    <A
                                        title={<T textName='footerContactNew' defaultStr='Связаться с нами' page='/' />}
                                        href={'/[lang]/contact'}
                                        as={`/${lang}/contact`}
                                        styles={{
                                            ...styles.mainMenuBtn,
                                            ...styles.mainMenuBtnFooter,
                                            ...styles.mainMenuBtnPrimary
                                        }}
                                        effects={{ hover: styles.mainMenuBtnPrimaryHover }}
                                    />
                                </Div>
                                {/*<Div*/}
                                {/*    styles={{*/}
                                {/*        ...styles.footerSocialTitleMain,*/}
                                {/*        'margin-top': '14px',*/}
                                {/*        'margin-bottom': '8px'*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <T textName='payFooterTittle' defaultStr='Способы оплаты' page='all' />*/}
                                {/*</Div>*/}
                                <Div styles={styles.footerPaymentLogo}>
                                    <Img
                                        width={'200'}
                                        height={'72'}
                                        src={visamaster.src}
                                        styles={styles.footerPaymentLogoImg}
                                        loading='lazy'
                                    />
                                </Div>
                            </Div>
                        </>
                        : <Div>
                            <Div styles={styles.singleFooterSocialWrapperMain}>
                                <Div styles={styles.footerSocialTitleMain}>
                                    <T textName='socialFooterTittle' defaultStr='Наши социальные сети' page='all' />
                                </Div>

                                <Div styles={styles.footerSocialMain}>
                                    {footerInfo.social.map((elem, idx) =>
                                        <>
                                            <Div key={`social${idx}`} styles={styles.footerSocialIconMainItem}>
                                                <Div
                                                    key={`social${idx}`}
                                                    styles={styles.footerSocialIconMain}
                                                    data-uitest='70_uitest'
                                                    onClick={() => {
                                                        elem.link ? window.open(elem.link, '_blank') : false;
                                                    }}
                                                    effects={{ hover: { ...styles.footerSocialIconMainHover } }}
                                                >
                                                    {svgSprite(elem.icon, {
                                                        style: {
                                                            width: elem.width,
                                                            height: elem.height,
                                                            fill: 'none'
                                                        }
                                                    })}
                                                </Div>
                                            </Div>
                                        </>)}
                                    <Div key={`social${5}`} styles={styles.footerSocialIconMainItem}>
                                        <Div
                                            key={`social${5}`}
                                            styles={styles.footerSocialIconMain}
                                            onClick={() => {
                                                true ? window.open('https://t.me/alhadayainfo_bot', '_blank') : false;
                                            }}
                                            effects={{ hover: { ...styles.footerSocialIconMainHover } }}
                                        >
                                            {svgSprite('telegram-alt', {
                                                style: {
                                                    width: '17px',
                                                    height: '17px',
                                                    fill: '#BCA57F',
                                                    margin: '2px 2px 0px 0px'
                                                }
                                            })}
                                        </Div>
                                    </Div>
                                </Div>

                                <Div styles={styles.footerInfoContactMain}>
                                    <Div styles={styles.footerAddress}>
                                        {svgSprite('location_1', { width: '24px', height: '24px' })}
                                        <P styles={styles.footerAddressText}>{footerInfo?.address}</P>
                                    </Div>
                                    <Div styles={styles.footerContactTitle}>
                                        <T textName='socialFooterTelTech' defaultStr='Техническая поддержка' page='all' />
                                    </Div>
                                    <Div styles={{ ...styles.footerPhoneWrap, ...styles.wrapMob }}>
                                        <BlankLink
                                            icon={svgSprite('whatsapp_1', { width: '24px', height: '24px' })}
                                            styles={{ ...styles.footerInfoPhoneMain }}
                                            title={footerInfo?.whatsapp_tech?.number}
                                            href={footerInfo?.whatsapp_tech?.link}
                                            effects={{ hover: styles.footerInfoHover }}
                                        />
                                    </Div>
                                    <Div styles={styles.footerContactTitle}>
                                        <T textName='socialFooterTelCons' defaultStr='Консультация по продуктам' page='all' />
                                    </Div>
                                    <Div styles={styles.footerPhoneWrap}>
                                        <BlankLink
                                            icon={svgSprite('phone_1', { width: '24px', height: '24px' })}
                                            styles={{ ...styles.footerInfoPhoneMain }}
                                            title={footerInfo?.phone?.title}
                                            href={`tel:${footerInfo?.phone?.number}`}
                                            effects={{ hover: styles.footerInfoHover }}
                                        />
                                    </Div>
                                    <Div styles={styles.footerPhoneWrap}>
                                        <BlankLink
                                            icon={svgSprite('whatsapp_1', { width: '24px', height: '24px' })}
                                            styles={{ ...styles.footerInfoPhoneMain }}
                                            title={footerInfo?.whatsapp_cons?.number}
                                            href={footerInfo?.whatsapp_cons?.link}
                                            effects={{ hover: styles.footerInfoHover }}
                                        />
                                    </Div>
                                </Div>

                                <Div styles={styles.footerSocialMainContact}>
                                    <A
                                        title={<T textName='footerContactNew' defaultStr='Связаться с нами' page='/' />}
                                        href={'/[lang]/contact'}
                                        as={`/${lang}/contact`}
                                        styles={{
                                            ...styles.mainMenuBtn,
                                            ...styles.mainMenuBtnFooter,
                                            ...styles.mainMenuBtnPrimary
                                        }}
                                        effects={{ hover: styles.mainMenuBtnPrimaryHover }}
                                    />
                                </Div>
                                {/*<Div*/}
                                {/*    styles={{*/}
                                {/*        ...styles.footerSocialTitleMain,*/}
                                {/*        ...{*/}
                                {/*            'margin-top': '12px !important',*/}
                                {/*            'margin-bottom': '8px'*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <T textName='payFooterTittle' defaultStr='Способы оплаты' page='all' />*/}
                                {/*</Div>*/}
                                <Div styles={styles.footerPaymentLogo}>
                                    <Img
                                        width={'200'}
                                        height={'72'}
                                        src={visamaster.src}
                                        styles={styles.footerPaymentLogoImg}
                                        loading='lazy'
                                    />
                                </Div>
                            </Div>
                        </Div>
                    }
                </Div>
                <Div styles={{ ...styles.footerInfoMainBottom }}>
                    <Div styles={{ ...styles.footerInfoMainWrap, ...styles.footerInfoMainWrapBottom }}>
                        <Div>
                            <T
                                textName='copyrightFooter'
                                vars={{ currentYear: currentYear, company: project?.company }}
                                defaultStr='© ${currentYear} ${company}. Все права защищены'
                                page='all'
                            />
                        </Div>
                        <Div styles={{ ...styles.dev, ...styles.devMain }}>
                            <P styles={styles.devText}>
                                <T textName='devText' defaultStr='Разработка' page='all' />
                                {' — '}
                            </P>
                            <BlankLink
                                styles={{ ...styles.devLinkBottom, ...styles.cursorPointer }}
                                title={<T textName='FlawlessCore-2' defaultStr='FlawlessMLM' page='all' />}
                                // onPress={() => Linking.openURL('https://flawlessmlm.com/')}
                                href='https://flawlessmlm.com/'
                            />
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

export default Styles(SingleFooter, getStyles);