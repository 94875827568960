// VideoPlayer.js

import React, { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css'; // Додаємо стилі Plyr


function VideoPlayer({ videoSrc, autoplay }) {
    const videoRef = useRef(null);

    useEffect(() => {
        const youtubeLogo = document.querySelector('.ytp-large-play-button.ytp-button.ytp-large-play-button-red-bg');
        if (youtubeLogo) {
            youtubeLogo.remove();
        }
        const player = new Plyr(videoRef.current, {
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
            youtube: { // Додайте цю конфігурацію, щоб відключити YouTube-плеєр
                noCookie: true // Відключаємо YouTube плеєр
            },
            autoplay: autoplay,
            muted: true
        });

        player.source = {
            type: 'video',
            sources: [
                {
                    src: videoSrc,
                    provider: 'youtube'
                }
            ]

        };

        // return () => {
        //     if (player) {
        //         player.destroy();
        //     }
        // };
    }, [autoplay, videoSrc]);

    return <video ref={videoRef} />;
}

export default VideoPlayer;