import React from "react";
import {motion} from 'framer-motion'
import styles from "./Top.module.scss";
import T from "../../../../common/components/T";
import { Div, H2, H5 } from "../../../../hybrid/wrappers";


export const Top = () => {

	return (
		<Div id='main-section' className={styles.wrapper}>
			<Div className="main-page__container">
				<motion.h2 className={styles.title}
						   initial={{
							   x: -300,
							   opacity: 0
						   }}
						   animate={{
							   x: 0,
							   opacity: 1
						   }}
						   transition={{
							   delay: 0.2,
							   duration: 0.5
						   }}
				>
					<T textName="topPageTitle" defaultStr="Стань нашим бизнес-партнером и зарабатывай от 300.000 тг в месяц" page="/"/>
				</motion.h2>
				<motion.h5 className={styles.subtitle}
						   initial={{
							   x: -300,
							   opacity: 0
						   }}
						   animate={{
							   x: 0,
							   opacity: 1
						   }}
						   transition={{
							   delay: 0.5,
							   duration: 0.5
						   }}
				>
					<span>
						<T textName="topPageMainPhrase1" defaultStr="вместе " />
					</span>
					<T textName="topPageMainPhrase2" defaultStr="с известным брендом на твердом продукте" />
				</motion.h5>
			</Div>
		</Div>
	);
};
